import React, { useLayoutEffect, useState } from "react"

import BuilderPdf from "../components/builder/builder-pdf"
import { queryParams } from "../helpers"
import { getUserData } from "../global/firebase"
import LoadingSpinner from "../components/loading-spinner"
import { navigate } from "gatsby"

const PdfPage = ({ location }) => {
  const [pdfDetails, setDetails] = useState(null)

  useLayoutEffect(() => {
    const { id } = queryParams(location.search, ["id"])
    const getVariationDetails = async id => {
      const {
        data: { data, success },
      } = await getUserData({
        id,
        env: "development",
      })

      if (!success) {
        navigate("/")
      } else {
        setDetails({
          ...data.builder.selected,
          cost: data.builder.cost,
          variations: {
            ...data.builder.variations,
          },
        })
      }
    }

    getVariationDetails(id)
  }, [location])

  if (!pdfDetails) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <LoadingSpinner />
      </div>
    )
  }

  return <BuilderPdf modal={pdfDetails} />
}

export default PdfPage
